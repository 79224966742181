import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import {
  FaFacebookF,
  FaInstagram,
  FaPhoneVolume,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import { toast } from 'sonner'
import { z } from 'zod'

import { newsletter } from '@/api/newsletter'
import { voluntarios } from '@/api/voluntarios'

import logo from '../assets/logoFooter.png'
import { NavLink } from './nav-link'
import { Button } from './ui/button'
import { Input } from './ui/input'

const voluntaryForm = z.object({
  email: z
    .string()
    .min(1, 'E-mail é obrigatório!')
    .email('Digite um e-mail válido'),
})

const newsForm = z.object({
  email: z
    .string()
    .min(1, 'E-mail é obrigatório!')
    .email('Digite um e-mail válido'),
})

type VoluntaryForm = z.infer<typeof voluntaryForm>

type NewsForm = z.infer<typeof newsForm>

export function Footer() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<NewsForm>({
    resolver: zodResolver(newsForm),
  })

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { isSubmitting: isSubmitting2, errors: errors2 },
  } = useForm<VoluntaryForm>({
    resolver: zodResolver(voluntaryForm),
  })

  const { mutateAsync: voluntariosFn } = useMutation({
    mutationFn: voluntarios,
    onSuccess: () => {
      toast.success(
        '😄 Sua mensagem foi enviada com sucesso, fique de olho na sua caixa de e-mail!',
      )
      reset2()
    },
    onError: () => {
      toast.error('🙁 Erro, tente novamente mais tarde!')
      reset2()
    },
  })

  const { mutateAsync: newsletterFn } = useMutation({
    mutationFn: newsletter,
    onSuccess: () => {
      toast.success(
        '😄 Sua mensagem foi enviada com sucesso, fique de olho na sua caixa de e-mail!',
      )
      reset()
    },
    onError: () => {
      toast.error('🙁 Erro, tente novamente mais tarde!')
      reset()
    },
  })

  async function handleVoluntary(data: VoluntaryForm) {
    const voluntarioData = {
      email: data.email,
    }

    await voluntariosFn({ data: voluntarioData })
  }

  async function handleNewsletter(data: NewsForm) {
    const newsletterData = {
      email: data.email,
    }

    await newsletterFn({ data: newsletterData })
  }

  return (
    <footer
      className="flex h-80 items-center justify-center bg-purple-950 max-[1024px]:h-auto lg:h-[30rem]"
      id="contato"
    >
      <div className="container pt-16">
        <div className="flex justify-between max-[1024px]:flex-col max-[1024px]:items-center max-[1024px]:justify-center max-[1024px]:gap-8 max-[1024px]:text-center">
          <div className="max-[1024px]:w-full">
            <nav className="grid grid-cols-1 max-[1024px]:gap-4 lg:gap-4 xl:gap-2">
              <NavLink
                to="/a-ong"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                A Ong
              </NavLink>
              <NavLink
                to="/quero-uma-peruca"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Quero uma peruca
              </NavLink>
              <NavLink
                to="/como-ajudar"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Como ajudar
              </NavLink>
              <NavLink
                to="/confeccao-de-perucas"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Confecção de Perucas
              </NavLink>
              <NavLink
                to="/depoimentos"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Depoimentos
              </NavLink>

              <NavLink
                to="/banco-de-perucas-movel"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Agenda
              </NavLink>
              <NavLink
                to="/pontos-de-coleta"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Pontos de Coleta
              </NavLink>
              <NavLink
                to="/duvidas-frequentes"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Dúvidas Frequentes
              </NavLink>
              <a
                href="#contato"
                title="contato"
                className="after:none font-roboto text-xs font-medium uppercase text-white max-[1024px]:text-base xl:text-sm 2xl:text-base"
              >
                Contato
              </a>
            </nav>
          </div>
          <div className="max-[1024px]:w-full">
            <h1 className="pb-4 font-roboto text-xl font-bold text-white">
              Quer receber novidades sobre a{' '}
              <br className="max-[480px]:hidden" /> Cabelegria ? Assine nossa
              Newsletter:
            </h1>
            <form
              className="mb-4 flex gap-4 max-[1024px]:justify-center"
              onSubmit={handleSubmit(handleNewsletter)}
            >
              <div className="space-y-2">
                <Input
                  id="email"
                  type="email"
                  placeholder="E-mail"
                  {...register('email')}
                  autoComplete="off"
                  className={
                    errors.email?.message
                      ? 'focus:ring-red border-[1px] border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:text-red-600 focus:ring-red-600 focus-visible:outline-none focus-visible:ring-red-600'
                      : 'focus:ring-red border-[1px] border-solid bg-transparent font-roboto font-medium text-white outline-none placeholder:font-roboto placeholder:text-white focus:ring-purple-950 focus-visible:outline-none focus-visible:ring-purple-950'
                  }
                />
                {errors.email?.message && (
                  <p className="pt-1 text-left text-sm text-red-600">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <Button
                disabled={isSubmitting}
                type="submit"
                variant="transparent"
                className="font-roboto text-sm font-medium text-white hover:bg-white hover:text-purple-950"
              >
                Enviar
              </Button>
            </form>
            <h2 className="pb-4 font-roboto text-xl font-bold text-white">
              Nos encontre nas redes sociais
            </h2>
            <div className="flex gap-4 max-[1024px]:justify-center">
              <a
                href="https://www.instagram.com/cabelegria/"
                title="Instagram Cabelegria"
                target="_blank"
                className="flex h-6 w-6 items-center justify-center rounded-full bg-white"
                rel="noreferrer"
              >
                <FaInstagram color="#482682" />
              </a>
              <a
                href="https://www.tiktok.com/@cabelegria?_t=8lp9enKiNgz&_r=1"
                title="Tiktok Cabelegria"
                target="_blank"
                className="flex h-6 w-6 items-center justify-center rounded-full bg-white"
                rel="noreferrer"
              >
                <FaTiktok color="#482682" />
              </a>
              <a
                href="https://www.youtube.com/ongcabelegria"
                title="Youtube Cabelegria"
                target="_blank"
                className="flex h-6 w-6 items-center justify-center rounded-full bg-white"
                rel="noreferrer"
              >
                <FaYoutube color="#482682" />
              </a>
              <a
                href="https://www.facebook.com/cabelegria"
                title="Facebook Cabelegria"
                target="_blank"
                className="flex h-6 w-6 items-center justify-center rounded-full bg-white"
                rel="noreferrer"
              >
                <FaFacebookF color="#482682" />
              </a>
            </div>
          </div>
          <div className="max-[1024px]:w-full">
            <h3 className="pb-4 font-roboto text-xl font-bold text-white">
              Quero ser um voluntário!
            </h3>
            <form
              className="mb-8 flex gap-4 max-[1024px]:justify-center"
              onSubmit={handleSubmit2(handleVoluntary)}
            >
              <div className="space-y-2">
                <Input
                  id="email"
                  type="email"
                  placeholder="E-mail"
                  {...register2('email')}
                  autoComplete="off"
                  className={
                    errors2.email?.message
                      ? 'focus:ring-red border-[1px] border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:text-red-600 focus:ring-red-600 focus-visible:outline-none focus-visible:ring-red-600'
                      : 'focus:ring-red border-[1px] border-solid bg-transparent font-roboto font-medium text-white outline-none placeholder:font-roboto placeholder:text-white focus:ring-purple-950 focus-visible:outline-none focus-visible:ring-purple-950'
                  }
                />
                {errors2.email?.message && (
                  <p className="pt-1 text-left text-sm text-red-600">
                    {errors2.email.message}
                  </p>
                )}
              </div>

              <Button
                disabled={isSubmitting2}
                type="submit"
                variant="transparent"
                className="font-roboto text-sm font-medium text-white hover:bg-white hover:text-purple-950"
              >
                Enviar
              </Button>
            </form>
            <h4 className="pb-4 font-roboto text-xl font-bold text-white">
              Entre em contato conosco!
            </h4>
            <div className="flex gap-4 max-[1024px]:justify-center">
              <a
                href="mailto:comunicacao@cabelegria.org"
                title="Email Cabelegria"
                target="_blank"
                rel="noreferrer"
                className="flex h-6 w-6 items-center justify-center rounded-full bg-white"
              >
                <IoIosMail color="#482682" />
              </a>
              <a
                href="tel:+551132415795"
                title="Telefone Cabelegria"
                target="_blank"
                rel="noreferrer"
                className="flex h-6 w-6 items-center justify-center rounded-full bg-white"
              >
                <FaPhoneVolume color="#482682" />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=551132415795"
                title="Whatsapp Cabelegria"
                target="_blank"
                rel="noreferrer"
                className="flex h-6 w-6 items-center justify-center rounded-full bg-white"
              >
                <FaWhatsapp color="#482682" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between pb-4 pt-12 max-[1024px]:flex-col max-[1024px]:gap-8">
          <p className="font-roboto text-sm font-normal text-white max-[1024px]:text-center">
            Endereço: Av. Luíz Dumont Villares, 1.160 - Sala 86 - Jardim São
            Paulo - São Paulo/SP, 02085-100 <br />
            <br className="hidden max-[480px]:block" />
            Horário de funcionamento: de Segunda a Sexta, das 08h às 15h <br />{' '}
            *Somente com horário agendado{' '}
          </p>
          <img
            src={logo}
            className="relative z-10 h-auto w-[344px] max-[480px]:h-auto max-[480px]:w-[220px]"
            loading="lazy"
            width="344"
            height="65"
            alt="Cabelegria Logo"
          />
        </div>
      </div>
    </footer>
  )
}

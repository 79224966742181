import { zodResolver } from '@hookform/resolvers/zod'
import { ChangeEvent, Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaArrowUp } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { z } from 'zod'

import { useMain } from '@/contexts/mainContext'
import { QueroUmaPerucaDTOStep5 } from '@/dtos/QueroUmaPerucaDTO'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Textarea } from '../ui/textarea'

const lastFormSchema = z.object({
  comoficousabendo: z
    .string()
    .min(1, 'Não esqueça de nos contar como você conheceu a Cabelegria!'),
})

type LastFormData = z.infer<typeof lastFormSchema>

export function QueroUmaPerucaFormStep5() {
  const { progress, setProgress, setFormValues } = useMain()
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
  const [message, setMessage] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LastFormData>({
    resolver: zodResolver(lastFormSchema),
    mode: 'all',
  })

  function handleInputMultipleFileChange({
    currentTarget: { files },
  }: ChangeEvent<HTMLInputElement>) {
    if (files && files.length) {
      setUploadedFiles((existing) => existing.concat(Array.from(files)))
    }

    setMessage(false)
  }

  function handleDeleteFiles(currentFile: string) {
    const deletedFiles = uploadedFiles.filter(
      (file: File) => file.name !== currentFile,
    )

    setUploadedFiles(deletedFiles)
  }

  function handleBeforeSend() {
    if (uploadedFiles.length < 2) {
      setTimeout(() => {
        setMessage(true)
      }, 0)
    }
  }

  async function SendQueroUmaPerucaFormData(values: QueroUmaPerucaDTOStep5) {
    setFormValues({
      ...values,
      docs: uploadedFiles,
    })
    setProgress(progress + 1)
  }

  return (
    <>
      <h2 className="font-roboto text-2xl font-bold text-pink-650 max-[1279px]:text-center max-[480px]:text-lg">
        Último passo:
      </h2>
      <form
        className="mt-8 flex flex-col items-center justify-center gap-8 max-[1024px]:w-full max-[480px]:gap-4"
        onSubmit={handleSubmit(SendQueroUmaPerucaFormData)}
        encType="multipart/form-data"
      >
        <div className="flex w-full gap-4 max-[540px]:flex-col">
          <div className="w-full">
            <label
              htmlFor="foto-documentos"
              className="flex h-20 cursor-pointer items-center justify-center gap-4 rounded-md border border-solid border-pink-650 bg-pink-650 p-12 font-roboto text-xs font-medium text-white
              max-[480px]:h-[150px]"
            >
              <FaArrowUp className="max-[600px]:hidden" size={20} />
              <span className="block leading-4 max-[540px]:text-center">
                Anexe os seguintes documentos: <br />
                - RG/CPF
                <br />
                - Laudo Médico
                <br />
                - Comprovante de Quimioterapia
                <br />- Uma foto sua de referência, se desejar
              </span>
            </label>
            <Input
              type="file"
              id="foto-documentos"
              onChange={handleInputMultipleFileChange}
              multiple
              className="focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650"
            />
            {uploadedFiles.length === 0 && message && (
              <p className="pt-1 text-left text-sm text-red-600">
                Por favor anexe os documentos.
              </p>
            )}
            {uploadedFiles.length >= 1 &&
              uploadedFiles.length < 2 &&
              message && (
                <p className="pt-1 text-left text-sm text-red-600">
                  Por favor anexe os documentos.
                </p>
              )}
            {uploadedFiles.length > 0 && (
              <div className="pt-2">
                <span className="font-roboto text-base font-normal text-pink-650">
                  <strong>Arquivos: </strong>
                </span>
                <p className="flex flex-col gap-2 pt-2 font-roboto text-base font-normal text-pink-650 max-[320px]:text-xs">
                  {[...uploadedFiles].map((file, index) => {
                    return (
                      <Fragment key={index}>
                        <div className="flex gap-2">
                          <span
                            className="inline-block h-5 w-5 cursor-pointer bg-red-500"
                            onClick={() => handleDeleteFiles(file.name)}
                          >
                            <IoClose color="white" size={20} />
                          </span>
                          {file.name.length > 14
                            ? file.name.slice(11)
                            : file.name}
                        </div>
                      </Fragment>
                    )
                  })}
                </p>
              </div>
            )}
          </div>
        </div>
        <Textarea
          placeholder="Nos conte como ficou sabendo da Cabelegria:"
          {...register('comoficousabendo')}
          className={
            errors.comoficousabendo?.message
              ? 'focus:ring-red resize-none border border-solid border-red-600 bg-transparent font-roboto font-medium normal-case text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
              : 'focus:ring-red resize-none border border-solid border-pink-650 bg-transparent font-roboto font-medium normal-case text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
          }
        />
        {errors.comoficousabendo?.message && (
          <p className="pt-1 text-left text-sm text-red-600">
            {errors.comoficousabendo.message}
          </p>
        )}
        <Button
          type="submit"
          variant="transparent"
          onClick={handleBeforeSend}
          disabled={message}
          className="w-1/4 border-pink-650 bg-pink-650 font-roboto text-sm font-medium text-white max-[540px]:w-full"
        >
          Finalizar
        </Button>
      </form>
    </>
  )
}

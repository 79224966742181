export const requisitos = [
  {
    id: 1,
    question: 'Porque o mínimo é 20cm?',
    answer:
      'Porque na confecção de peruca perdemos quase 5 cm de cabelo no processo, sendo menor que isso não conseguimos um comprimento suficiente para confeccionar as perucas.',
  },
  {
    id: 2,
    question: 'Posso eu mesmo cortar ou preciso ir a um cabelereiro?',
    answer:
      'Você mesmo pode cortar, não temos exigências sobre quem cortará, só pedimos que o cabelo venha bem amarrado, com elástico e seco, lembrando que o cabelo tem que estar sem chapinha e sem escova.',
  },
  {
    id: 3,
    question: 'Meu cabelo é repicado vocês aceitam?',
    answer:
      'Sim, apenas pedimos que a menor parte do repicado não esteja muito abaixo dos 20cm.',
  },
  {
    id: 4,
    question: 'Vocês aceitam cabelos cacheados e afros? Como medir?',
    answer:
      'Aceitamos e precisamos muito. No caso dos cabelos cacheados e afros, de preferência, que tenha 15cm seco e enrolado.',
  },
  {
    id: 5,
    question:
      'Cabelo tem prazo de validade? Tenho um cabelo guardado há anos, posso enviar mesmo assim?',
    answer:
      'Se ele estiver em boas condições (sem mofo, sem nós) nós aceitamos sim.',
  },
  {
    id: 6,
    question:
      'Faço química no cabelo, é melhor doar antes ou depois de aplicá-la?',
    answer:
      'Pedimos que nos mande antes de colocar a química, devido ao cheiro que fica no cabelo durante os primeiros dias.',
  },
  {
    id: 7,
    question:
      'Como exatamente tenho que pedir para o cabelereiro cortar meu cabelo?',
    answer:
      'É muito fácil! Se for ao cabeleireiro, avise-o que é para adoção. O corte é o convencional, só precisamos que venha bem amarrado com um elástico. Pode-se cortar em várias mechas ou num único rabo de cavalo. Corte o cabelo, de preferência já seco ou espere secar. O elástico precisa estar bem firme, para que o cabelo não solte.',
  },
  {
    id: 8,
    question: 'É necessário algum cuidado especial?',
    answer:
      'O cabelo precisa estar seco e amarrado. Pedimos que não faça trança nele. Não é necessário colocá-lo em nenhum lugar especial, só precisamos que venha dentro de um saco plástico.',
  },
  {
    id: 9,
    question:
      'Preciso guardar o cabelo em algum tipo de saco plástico especial?',
    answer: 'Não, qualquer saco serve, desde que o cabelo esteja 100% seco.',
  },
  {
    id: 10,
    question: 'Amarrei o cabelo, mas soltou, posso enviar mesmo assim?',
    answer:
      'Depende de como ele se espalhou. Se ficou muito espalhado, infelizmente não temos como utilizar.',
  },
]

export const envio = [
  {
    id: 1,
    question:
      'Me deram cabelos de pessoas desconhecidas, posso mandar todos juntos?',
    answer: 'Pode sim, só pedimos que envie em sacos plásticos separados.',
  },
  {
    id: 2,
    question: 'Posso enviar em envelope ou precisa ser caixa?',
    answer:
      'Nós não temos preferência sobre essa questão, mas às vezes os Correios têm regras próprias.',
  },
  {
    id: 3,
    question: 'Envio via PAC ou Sedex?',
    answer: 'Nós não temos preferência, envie como for mais conveniente.',
  },
  {
    id: 4,
    question: 'Posso levar pessoalmente?',
    answer:
      'Pode sim, basta levar em um de nossos pontos de coletas, os endereços estão disponíveis na aba Pontos de coletas em nosso site.',
  },
  {
    id: 5,
    question: 'Como saber se minha doação chegou?',
    answer:
      'Você pode consultar se a sua doação já consta em nosso banco através da busca pelos doadores aqui. Basta digitar o CEP do remetente da doação. Caso não encontre, você pode realizar uma nova busca através do CEP 00000-000. Importante: os certificados aparecem no site cerca de dois meses após a data de envio da doação. Mas se ainda assim a sua doação não for encontrada, você pode entrar em contato com o Cabelegria através do e-mail cabelegria@gmail.com, com o título Certificado não encontrado, informando os seguintes dados do doador: nome completo, endereço e data aproximada do envio da doação.',
  },
  {
    id: 6,
    question: 'Como confirmar a chegada do cabelo de uma forma rápida?',
    answer:
      'A forma mais rápida de confirmar a doação é através do código de rastreamento que os Correios fornecem dentro da nota fiscal do envio. No próprio site dos Correios você pode saber onde está o seu cabelo, mas você pode também verificar através da aba “doadores” existente no site, porém temos um prazo de até um mês e meio para cadastrar as doações.',
  },
  {
    id: 7,
    question: 'Vocês reembolsam o custo de envio dos Correios?',
    answer:
      'Infelizmente não temos recursos financeiros suficientes para devolver este valor aos doadores.',
  },
]

export const certificado = [
  {
    id: 1,
    question: 'O que é o Certificado?',
    answer:
      'O certificado é uma forma que encontramos de agradecer aos nossos doadores. Ele é digital e é possível encontrá-lo na aba de doadores do nosso site, ao digitar o CEP do remetente. Depois do RECEBIMENTO o certificado do doador deve aparecer no site dentro de um mês e meio.',
  },
  {
    id: 2,
    question: 'Como faço para gerar o meu certificado de doação?',
    answer:
      'Como forma de agradecimento, o Cabelegria disponibiliza em seu site um certificado digital de doação cerca de dois meses após a sua doação ter sido enviada. Por isso, é importante que, antes de enviar, você se certifique de que as informações no envelope estão legíveis – inclusive endereço e CEP. Caso tenha mais de uma doação, identifique os cabelos com o nome de cada doador, para que todos encontrem seus certificados. <br /><br /> Caso já tenha feito a sua doação e queira encontrar o seu certificado, <a href="/certificado" title="Clique aqui" style="text-decoration:underline"><strong>clique aqui</strong></a>.',
  },
  {
    id: 3,
    question: 'Meu nome está errado no certificado. Como corrigir?',
    answer:
      'Você pode solicitar a correção do seu nome no certificado através do e-mail contato@cabelegria.org, com o título Correção de nome – Certificado, informando o seu nome completo de forma correta, o nome que encontrou no site, o endereço informado na doação e a data aproximada do envio da doação.',
  },
  {
    id: 4,
    question: ' Por que minha doação foi recusada?',
    answer:
      'Não recusamos doações, por favor, verifique se a sua doação foi mandada para o endereço correto. <br /><br /> A/C Cabelegria <br /> Caixa Postal – 75207 <br /> São Paulo/SP <br /> CEP. 02415-972',
  },
  {
    id: 5,
    question:
      'Esqueci de colocar as informações no remetente, posso receber o certificado?',
    answer:
      'Infelizmente não será possível, pois cadastramos o remetente ou alguma informação que venha dentro da encomenda.',
  },
]

export const confeccao = [
  {
    id: 1,
    question:
      'Como saber se meu cabelo realmente será utilizado para a confecção de perucas?',
    answer:
      'Nós divulgamos todas as entregas feitas, basta conferir em nossas redes sociais. Fazemos entregas dentro de todo o Brasil.',
  },
  {
    id: 2,
    question: 'Quanto custa, em média, a produção de uma peruca?',
    answer: 'Custa, em média, 100 (cem) reais.',
  },
  {
    id: 3,
    question: 'Para quais instituições vocês mandam os cabelos?',
    answer:
      'Atendemos pacientes de diversos hospitais como GRAACC, ITACI, TUUCCA, Hospital Darcy Vargas, entre outros. Tambem enviamos perucas diretamente para pacientes, mediante a comprovação documental, via Correios para qualquer lugar do Brasil. Possuímos 4 Bancos de Perucas, você pode conhecer um pouco sobre eles na aba “Banco de Perucas”. Possuímos também um banco de perucas móvel onde levamos para diversos hospitais do Brasil.',
  },
  {
    id: 4,
    question: 'Posso conhecer a ONG?',
    answer:
      'As visitas podem ser realizadas em nossos Bancos de Perucas, basta checar o dia e horário de funcionamento na aba “Banco de Perucas”',
  },
  {
    id: 5,
    question:
      'Posso conhecer o paciente que receberá a peruca com o meu cabelo?',
    answer:
      'Infelizmente essa possibilidade não existe, pois precisamos de 5 a 7 cabelos para preencher uma peruca e não temos como fazer o controle de qual peruca tem o cabelo de quem.',
  },
]

import { useState } from 'react'
import { GoTriangleDown } from 'react-icons/go'
import { Link } from 'react-router-dom'

import { useMain } from '@/contexts/mainContext'

import logo from '../assets/logo.jpg'
import { NavLink } from './nav-link'
import { Button } from './ui/button'

export function Header() {
  const [openMenu, setOpenMenu] = useState(false)
  const { openSubMenu, setOpenSubMenu } = useMain()

  function handleSubMenu() {
    setOpenSubMenu(!openSubMenu)
  }

  return (
    <header className="header fixed z-10 flex h-[90px] w-full items-center bg-purple-950">
      <div className="container flex justify-between">
        <Link to="/" className="min-[1024px]:flex min-[1024px]:items-center">
          <img
            src={logo}
            width="244"
            height="46"
            className="relative z-10 h-[46px] w-[244px] max-[480px]:h-auto max-[480px]:w-[190px] min-[1024px]:h-auto min-[1024px]:w-[160px] min-[1200px]:h-[46px] min-[1200px]:w-[244px]"
            alt="Cabelegria Logo"
          />
        </Link>

        <button
          type="button"
          aria-label="Abrir Menu do site"
          onClick={() => setOpenMenu(!openMenu)}
          className={
            openMenu
              ? 'hamburger hamburger--stand is-active relative z-10 block lg:!hidden'
              : 'hamburger hamburger--spin block lg:!hidden'
          }
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>

        <nav
          className={
            openMenu
              ? 'fixed left-0 top-8 flex h-screen w-screen flex-col items-center justify-center gap-8 bg-purple-950 max-[320px]:gap-3 lg:flex'
              : 'hidden items-center lg:flex min-[1024px]:gap-3 min-[1100px]:gap-5 xl:gap-6'
          }
        >
          <div
            className={
              openSubMenu
                ? 'flex h-[163px] cursor-pointer items-center justify-center gap-2 font-roboto text-xs font-medium uppercase text-white max-[1024px]:hidden max-[1023px]:text-2xl lg:text-[0.65rem] xl:text-[0.75rem] min-[1600px]:text-sm'
                : 'flex cursor-pointer items-center justify-center gap-2 font-roboto text-xs font-medium uppercase text-white max-[1024px]:hidden max-[1023px]:text-2xl lg:text-[0.65rem] xl:text-[0.75rem] min-[1600px]:text-sm'
            }
            onMouseEnter={() => handleSubMenu()}
          >
            Quem Somos <GoTriangleDown size={18} />
            {openSubMenu && (
              <ul
                className="absolute top-14 ml-12
               rounded-md border border-purple-950 bg-white lg:ml-8 xl:ml-12"
              >
                <li className="relative px-2 py-1 font-roboto text-xs font-medium uppercase text-purple-950 after:absolute after:bottom-[-1px] after:left-0  after:block after:h-[1px] after:w-full after:origin-left after:scale-x-0 after:bg-purple-950 after:transition after:duration-300 after:content-[''] hover:border-purple-950 after:hover:scale-x-100 data-[current=true]:border-b-[1px] data-[current=true]:font-bold max-[1023px]:text-2xl max-[580px]:p-0 lg:text-[0.65rem] min-[1024px]:p-1 xl:text-[0.75rem] min-[1400px]:p-2 min-[1600px]:text-sm min-[1600px]:after:bottom-[3px]">
                  <NavLink
                    to="/a-ong"
                    onClick={() => setOpenMenu(false)}
                    className="block"
                  >
                    A Ong
                  </NavLink>
                </li>
                <li className="relative px-2 py-1 font-roboto text-xs font-medium uppercase text-purple-950 after:absolute after:bottom-[-1px] after:left-0  after:block after:h-[1px] after:w-full after:origin-left after:scale-x-0 after:bg-purple-950 after:transition after:duration-300 after:content-[''] hover:border-purple-950 after:hover:scale-x-100 data-[current=true]:border-b-[1px] data-[current=true]:font-bold max-[1023px]:text-2xl max-[580px]:p-0 lg:text-[0.65rem] min-[1024px]:p-1 xl:text-[0.75rem] min-[1400px]:p-2 min-[1600px]:text-sm min-[1600px]:after:bottom-[3px]">
                  <NavLink
                    to="/depoimentos"
                    onClick={() => setOpenMenu(false)}
                    className="block"
                  >
                    Depoimentos
                  </NavLink>
                </li>
                <li className="relative px-2 pb-0 pt-1 font-roboto text-xs font-medium uppercase text-purple-950 after:absolute after:bottom-[-1px] after:left-0  after:block after:h-[1px] after:w-full after:origin-left after:scale-x-0 after:bg-purple-950 after:transition after:duration-300 after:content-[''] hover:border-purple-950 after:hover:scale-x-100 data-[current=true]:border-b-[1px] data-[current=true]:font-bold max-[1023px]:text-2xl max-[580px]:p-0 lg:text-[0.65rem] min-[1024px]:p-1 xl:text-[0.75rem] min-[1400px]:p-2 min-[1600px]:text-sm min-[1600px]:after:bottom-[3px]">
                  <NavLink
                    to="/confeccao-de-perucas"
                    onClick={() => setOpenMenu(false)}
                    className="block"
                  >
                    Confecção de Perucas
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
          <span className="min-[1025px]:hidden">
            <NavLink
              to="/a-ong"
              onClick={() => setOpenMenu(false)}
              onMouseEnter={() => setOpenSubMenu(false)}
            >
              A Ong
            </NavLink>
          </span>
          <NavLink
            to="/quero-uma-peruca"
            onClick={() => setOpenMenu(false)}
            onMouseEnter={() => setOpenSubMenu(false)}
          >
            Quero uma peruca
          </NavLink>
          <NavLink
            to="/como-ajudar"
            onClick={() => setOpenMenu(false)}
            onMouseEnter={() => setOpenSubMenu(false)}
          >
            Como ajudar
          </NavLink>
          <span className="min-[1025px]:hidden">
            <NavLink
              to="/confeccao-de-perucas"
              onClick={() => setOpenMenu(false)}
              onMouseEnter={() => setOpenSubMenu(false)}
            >
              Confecção de Perucas
            </NavLink>
          </span>
          <span className="min-[1025px]:hidden">
            <NavLink
              to="/depoimentos"
              onClick={() => setOpenMenu(false)}
              onMouseEnter={() => setOpenSubMenu(false)}
            >
              Depoimentos
            </NavLink>
          </span>
          <NavLink
            to="/banco-de-perucas-movel"
            onClick={() => setOpenMenu(false)}
            onMouseEnter={() => setOpenSubMenu(false)}
          >
            Agenda
          </NavLink>
          <NavLink
            to="/pontos-de-coleta"
            onClick={() => setOpenMenu(false)}
            onMouseEnter={() => setOpenSubMenu(false)}
          >
            Pontos de Coleta
          </NavLink>
          {/* <NavLink to="" onClick={() => setOpenMenu(false)}>
            Conquistas
          </NavLink> */}
          <NavLink
            to="/duvidas-frequentes"
            onClick={() => setOpenMenu(false)}
            onMouseEnter={() => setOpenSubMenu(false)}
          >
            Dúvidas Frequentes
          </NavLink>
          <a
            href="#contato"
            title="Contato"
            onClick={() => setOpenMenu(false)}
            onMouseEnter={() => setOpenSubMenu(false)}
            className="relative p-2 font-roboto text-xs font-medium uppercase text-white after:absolute after:bottom-[-1px] after:left-0 after:block after:h-[1px] after:w-full after:origin-left after:scale-x-0 after:bg-white after:transition after:duration-300 after:content-[''] hover:border-white after:hover:scale-x-100 data-[current=true]:border-b-[1px] data-[current=true]:font-bold max-[1023px]:text-2xl max-[580px]:p-0 lg:text-[0.65rem] min-[1024px]:p-0 xl:text-[0.75rem] min-[1400px]:p-2 min-[1600px]:text-sm min-[1600px]:after:bottom-[3px]"
          >
            Contato
          </a>
          <Link
            to="https://doe.cabelegria.org/s/single_step"
            target="_blank"
            className="mb-2 ml-2 mr-0 mt-2"
            onMouseEnter={() => setOpenSubMenu(false)}
          >
            <Button
              size="sm"
              className="bg-pink-650 font-roboto text-xs font-bold text-white hover:bg-white hover:text-pink-650 min-[481px]:col-span-2 min-[655px]:col-span-3 min-[1024px]:col-span-1"
            >
              QUERO DOAR
            </Button>
          </Link>
        </nav>
      </div>
    </header>
  )
}

import { createBrowserRouter } from 'react-router-dom'

import { AppLayout } from './pages/_layouts/app'
import { NotFound } from './pages/404'
import { AOng } from './pages/app/aong'
import { BancoDePerucasMovel } from './pages/app/bancodeperucasmovel'
import { Certificado } from './pages/app/certificado'
import { ComoAjudar } from './pages/app/comoajudar'
import { ComoAjudarFormCadastro } from './pages/app/comoajudarcadastro'
import { ConfeccaoDePerucas } from './pages/app/confeccao'
import { Depoimentos } from './pages/app/depoimentos'
import { Duvidas } from './pages/app/duvidas'
import { Home } from './pages/app/home'
import { PontosdeColeta } from './pages/app/pontosdecoleta'
import { QueroUmaPeruca } from './pages/app/queroumaperuca'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/como-ajudar',
        element: <ComoAjudar />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/como-ajudar/cadastro',
        element: <ComoAjudarFormCadastro />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/quero-uma-peruca',
        element: <QueroUmaPeruca />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/a-ong',
        element: <AOng />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/banco-de-perucas-movel',
        element: <BancoDePerucasMovel />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/pontos-de-coleta',
        element: <PontosdeColeta />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/duvidas-frequentes',
        element: <Duvidas />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/certificado',
        element: <Certificado />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/depoimentos',
        element: <Depoimentos />,
      },
    ],
  },
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/confeccao-de-perucas',
        element: <ConfeccaoDePerucas />,
      },
    ],
  },
])

import { useEffect, useState } from 'react'

import { certificado, confeccao, envio, requisitos } from '@/utils/answers'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select'

interface AccordionValues {
  id: number
  question: string
  answer: string
}

export function DuvidasBox() {
  const [selectValue, setSelectValue] = useState('')
  const [requisitosValue, setRequisitosValue] = useState<AccordionValues[]>([])
  const [envioValue, setEnvioValue] = useState<AccordionValues[]>([])
  const [certificadoValue, setCertificadoValue] = useState<AccordionValues[]>(
    [],
  )
  const [confeccaoValue, setConfeccaoValue] = useState<AccordionValues[]>([])

  async function handleDuvidas(value: string) {
    setSelectValue(value)
  }

  useEffect(() => {
    const requisitosQuestions = requisitos
    const envioQuestions = envio
    const certificadoQuestions = certificado
    const confeccaoQuestions = confeccao

    setRequisitosValue(requisitosQuestions)
    setEnvioValue(envioQuestions)
    setCertificadoValue(certificadoQuestions)
    setConfeccaoValue(confeccaoQuestions)
  }, [])

  return (
    <>
      <Select onValueChange={handleDuvidas} value={selectValue}>
        <SelectTrigger className="w-3/4 border-purple-950 font-roboto font-medium text-purple-950 focus:outline-none max-[1024px]:w-full">
          <SelectValue placeholder="Selecione um assunto" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="Requisitos">
              Requisitos para doar seu cabelo
            </SelectItem>
            <SelectItem value="Envio">Envio</SelectItem>
            <SelectItem value="Certificado">Certificado</SelectItem>
            <SelectItem value="Confeccao">Confecção das perucas</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <div className="w-3/4 max-[1024px]:mb-8 max-[1024px]:w-full">
        <Accordion
          type="single"
          collapsible
          className={selectValue === 'Requisitos' ? 'w-full' : 'hidden w-full'}
        >
          {requisitosValue.map((requisitosQuestions) => {
            return (
              <AccordionItem
                key={requisitosQuestions.id}
                value={requisitosQuestions.question}
              >
                <AccordionTrigger className="text-pretty text-left font-roboto text-base font-medium text-purple-950">
                  {requisitosQuestions.question}
                </AccordionTrigger>
                <AccordionContent className="text-pretty text-left font-roboto text-base font-normal text-purple-950">
                  {requisitosQuestions.answer}
                </AccordionContent>
              </AccordionItem>
            )
          })}
        </Accordion>
        <Accordion
          type="single"
          collapsible
          className={selectValue === 'Envio' ? 'w-full' : 'hidden w-full'}
        >
          {envioValue.map((envioQuestions) => {
            return (
              <AccordionItem
                key={envioQuestions.id}
                value={envioQuestions.question}
              >
                <AccordionTrigger className="text-pretty text-left font-roboto text-base font-medium text-purple-950">
                  {envioQuestions.question}
                </AccordionTrigger>
                <AccordionContent className="text-pretty text-left font-roboto text-base font-normal text-purple-950">
                  {envioQuestions.answer}
                </AccordionContent>
              </AccordionItem>
            )
          })}
        </Accordion>
        <Accordion
          type="single"
          collapsible
          className={selectValue === 'Certificado' ? 'w-full' : 'hidden w-full'}
        >
          {certificadoValue.map((certificadoQuestions) => {
            return (
              <AccordionItem
                key={certificadoQuestions.id}
                value={certificadoQuestions.question}
              >
                <AccordionTrigger className="text-pretty text-left font-roboto text-base font-medium text-purple-950">
                  {certificadoQuestions.question}
                </AccordionTrigger>
                <AccordionContent className="text-pretty text-left font-roboto text-base font-normal text-purple-950">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: certificadoQuestions.answer,
                    }}
                  />
                </AccordionContent>
              </AccordionItem>
            )
          })}
        </Accordion>
        <Accordion
          type="single"
          collapsible
          className={selectValue === 'Confeccao' ? 'w-full' : 'hidden w-full'}
        >
          {confeccaoValue.map((confeccaoQuestions) => {
            return (
              <AccordionItem
                key={confeccaoQuestions.id}
                value={confeccaoQuestions.question}
              >
                <AccordionTrigger className="text-pretty text-left font-roboto text-base font-medium text-purple-950">
                  {confeccaoQuestions.question}
                </AccordionTrigger>
                <AccordionContent className="text-pretty text-left font-roboto text-base font-normal text-purple-950">
                  {confeccaoQuestions.answer}
                </AccordionContent>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    </>
  )
}

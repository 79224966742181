import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { TimeFn } from '@/api/time'
import { AOngCard } from '@/components/a-ong-card'
import { TimeCard } from '@/components/time-card'
import { Button } from '@/components/ui/button'
import { useMain } from '@/contexts/mainContext'
import { TimeDTO } from '@/dtos/TimeDTO'

import AONG from '../../assets/aOng.jpg'

export function AOng() {
  const { setOpenSubMenu } = useMain()

  const { data: time } = useQuery({
    queryKey: ['time'],
    queryFn: () => TimeFn(),
  })

  useEffect(() => {
    setOpenSubMenu(false)
  }, [setOpenSubMenu])

  return (
    <>
      <Helmet title="A Ong" />
      <section className="flex items-center justify-center bg-pink-650 max-[1024px]:py-20 min-[1024px]:h-screen">
        <div className="container">
          <div className="flex items-center justify-between gap-4 max-[1024px]:flex-col">
            <div className="max-[1599px]:max-w-[25rem] max-[1279px]:max-w-full min-[1025px]:max-w-[24rem] min-[1600px]:max-w-[40rem]">
              <Fade delay={200}>
                <h1 className="pb-8 pt-20 font-roboto text-5xl font-bold leading-[4rem] text-white max-[1024px]:pt-12 max-[480px]:text-4xl">
                  Quem é a <br />
                  Cabelegria?
                </h1>
              </Fade>
              <Fade delay={250}>
                <p className="pb-4 text-sm font-normal text-white">
                  A Cabelegria é uma ONG que tem como propósito levar autoestima
                  e sorrisos à pacientes oncológicos e pessoas com patologias
                  que tenham como sintoma a queda dos cabelos.
                </p>
                <p className="pb-4 text-sm font-normal text-white">
                  O que começou lá em 2013 com uma simples vontade das amigas
                  Mariana Robrahn e Mylene Duarte em ajudar o próximo, se
                  transformou em uma das maiores ONGs de arrecadação de cabelo
                  do país, com mais de 420mil doações recebidas.
                </p>
                <p className="pb-4 text-sm font-normal text-white">
                  Hoje, 10 anos depois, já confeccionamos e doamos mais de 14
                  mil perucas, geramos renda para 9 costureiras e impactamos a
                  vida de milhares de pessoas através de nossas ações de
                  conscientização.
                </p>
                <p className="text-sm font-normal text-white">
                  Mas queremos mais! Hoje, além da confecção de perucas, a
                  Cabelegria busca viabilizar projetos que visam o resgate da
                  autoestima em várias áreas da vida dos pacientes através do
                  empoderamento feminino, acesso à informação, promoção da saúde
                  física e emocional, geração de renda, entre outros.
                </p>
              </Fade>
            </div>
            <div className="max-[1024px]:pt-8 min-[320px]:relative min-[1025px]:absolute min-[1025px]:right-0 min-[1025px]:w-[35rem] min-[1280px]:w-auto min-[1400px]:w-[56rem]">
              <img
                src={AONG}
                alt="Quem é a Cabelegria"
                className="h-screen object-cover max-[1024px]:h-auto xl:w-[50rem] min-[1400px]:w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center max-[1024px]:pt-20">
        <div className="container">
          <div className="flex gap-16 pt-20 max-[1024px]:flex-col max-[1024px]:gap-4 max-[1024px]:pt-0 min-[1400px]:justify-between">
            <div className="flex flex-col items-center justify-center">
              <div className="relative mx-auto h-[398px] w-full max-[480px]:h-[192px] min-[1025px]:h-[280px] min-[1025px]:w-[500px] min-[1200px]:h-[350px] min-[1200px]:w-[650px] min-[1400px]:h-[398px] min-[1400px]:w-[850px] min-[1600px]:h-[450px] min-[1600px]:w-[1000px]">
                <iframe
                  src="https://www.youtube.com/embed/PlhjVLskkAs?rel=0&amp;showinfo=0"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  className="h-full w-full"
                ></iframe>
              </div>
            </div>

            <div className="flex flex-col items-start justify-center gap-6 max-[1024px]:items-center max-[1024px]:pt-5 min-[1025px]:max-w-[24rem]">
              <Fade delay={250}>
                <h2 className="font-roboto text-5xl font-bold text-purple-950 max-[1024px]:text-center max-[480px]:text-3xl">
                  Ajude alguém a sorrir novamente
                </h2>
                <Link to="/como-ajudar">
                  <Button
                    variant="transparent"
                    size="lg"
                    className="border-purple-950 font-roboto text-xl font-medium text-purple-950 hover:bg-purple-950 hover:text-white"
                  >
                    Saiba Como
                  </Button>
                </Link>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center lg:pt-8">
        <div className="container">
          <div className="pb-20 pt-12 lg:flex lg:flex-col">
            <Fade delay={200}>
              <h2 className="text-center font-roboto text-5xl font-bold text-purple-950 max-[1024px]:text-center max-[1024px]:text-4xl max-[480px]:text-3xl">
                Como enxergamos o mundo
              </h2>
            </Fade>
            <div className="flex flex-col gap-6 max-[1024px]:pt-5">
              <div className="grid grid-cols-auto-fit-160 items-center justify-items-center gap-16 pt-12 max-[800px]:grid-cols-2 max-[767px]:gap-12 max-[580px]:grid-cols-1 max-[480px]:gap-6 lg:grid-cols-3">
                <Fade direction="left">
                  <AOngCard
                    bgColor="bg-purple-950"
                    title="Missão"
                    text="Resgatar a autoestima e fornecer apoio global ao paciente oncológico através da doação de perucas, atendimento multidisciplinar, empoderamento feminino, profissionalização e geração de renda."
                    mW="max-w-[14rem] max-[1024px]:max-w-[12rem]"
                  />
                </Fade>
                <Fade delay={200}>
                  <AOngCard
                    bgColor="bg-pink-650"
                    title="Visão"
                    text="Estabelecer Bancos de Perucas nos maiores hospitais do Brasil, proporcionando a doação de perucas e um atendimento humanizado aos pacientes oncológicos, e replicar o modelo da Casa Cabelegria nas principais capitais do país."
                    mW="max-w-[15rem] max-[1024px]:max-w-[12rem]"
                  />
                </Fade>
                <Fade direction="right">
                  <AOngCard
                    bgColor="bg-cyan-650"
                    title="Valores"
                    list
                    mW="max-w-44"
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex h-screen items-center justify-center max-[1024px]:h-auto max-[1024px]:pb-20">
        <div className="container">
          <div className="grid grid-cols-4 gap-16 max-[1024px]:grid-cols-3 max-[1024px]:gap-8 max-[670px]:grid-cols-2 max-[480px]:grid-cols-1">
            <Fade delay={200}>
              <TimeCard />
            </Fade>
            {time?.map((timeData: TimeDTO) => {
              return (
                <Fade delay={Number(timeData.t_delay)} key={timeData.t_id}>
                  <TimeCard
                    picture={`assets/funcionarios/${timeData.t_photo}`}
                    name={timeData.t_name}
                    role={timeData.t_role}
                  />
                </Fade>
              )
            })}
            <Fade delay={200}>
              <TimeCard voluntary={true} />
            </Fade>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center">
        <div className="container">
          <div className="pb-20 pt-12 lg:flex lg:flex-col">
            <Fade delay={200}>
              <h2 className="text-center font-roboto text-5xl font-bold text-purple-950 max-[1024px]:text-center max-[1024px]:text-4xl max-[480px]:text-3xl">
                Confira os balanços e relatórios de atividades
              </h2>
            </Fade>
            <div className="grid grid-cols-2 justify-items-center gap-5 pt-20 max-[670px]:grid-cols-1 max-[480px]:pt-8">
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={250}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_Cabelegria_2019b.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2019 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={300}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2019.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2019 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={350}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_Cabelegria_2020.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2020 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={400}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2020.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2020 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={450}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_2021.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2021 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={500}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2021.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2021 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={450}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_2022.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2022 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={500}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2022.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2022 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={450}>
                  <Link
                    to="/assets/pdfs/Relatório_de_atividades_2023.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2023 - Relatório de Atividades
                    </Button>
                  </Link>
                </Fade>
              </div>
              <div className="w-3/4 max-[1024px]:w-full">
                <Fade delay={500}>
                  <Link
                    to="/assets/pdfs/Balanço_Financeiro_e_Demonstrativo_2023.pdf"
                    target="_blank"
                  >
                    <Button
                      variant="transparent"
                      size="lg"
                      className="w-full rounded-full bg-purple-950 font-roboto text-xl font-medium text-white max-[480px]:text-base"
                    >
                      2023 - Balanço Social
                    </Button>
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

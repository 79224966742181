import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { DepoimentosDTO } from '@/dtos/DepoimentosDTO'
import {
  QueroUmaPerucaDTO,
  QueroUmaPerucaDTOStep1,
  QueroUmaPerucaDTOStep2,
  QueroUmaPerucaDTOStep3,
  QueroUmaPerucaDTOStep4,
  QueroUmaPerucaDTOStep5,
} from '@/dtos/QueroUmaPerucaDTO'
import { SlidesDTO } from '@/dtos/SlidesDTO'

interface MainContextData {
  openSubMenu: boolean
  setOpenSubMenu: Dispatch<SetStateAction<boolean>>
  telefone: string
  setTelefone: Dispatch<SetStateAction<string>>
  phoneMessage: boolean
  setPhoneMessage: Dispatch<SetStateAction<boolean>>
  CEP: string
  setCEP: Dispatch<SetStateAction<string>>
  cepMessage: boolean
  setCEPMessage: Dispatch<SetStateAction<boolean>>
  endereco: string
  setEndereco: Dispatch<SetStateAction<string>>
  enderecoMessage: boolean
  setEnderecoMessage: Dispatch<SetStateAction<boolean>>
  cidade: string
  setCidade: Dispatch<SetStateAction<string>>
  cidadeMessage: boolean
  setCidadeMessage: Dispatch<SetStateAction<boolean>>
  uf: string
  setUF: Dispatch<SetStateAction<string>>
  cidadeField: string
  setCidadeField: Dispatch<SetStateAction<string>>
  success: boolean
  setSuccess: Dispatch<SetStateAction<boolean>>
  progress: number
  setProgress: Dispatch<SetStateAction<number>>
  protocolo: string
  setProtocolo: Dispatch<SetStateAction<string>>
  data: QueroUmaPerucaDTO
  setData: Dispatch<SetStateAction<QueroUmaPerucaDTO>>
  setFormValues: (
    values:
      | QueroUmaPerucaDTOStep1
      | QueroUmaPerucaDTOStep2
      | QueroUmaPerucaDTOStep3
      | QueroUmaPerucaDTOStep4
      | QueroUmaPerucaDTOStep5,
  ) => void
  homeSlide: SlidesDTO[]
  setHomeSlide: Dispatch<SetStateAction<SlidesDTO[]>>
  queroUmaPerucaSlide: SlidesDTO[]
  setQueroUmaPerucaSlide: Dispatch<SetStateAction<SlidesDTO[]>>
  comoAjudarSlide: SlidesDTO[]
  setComoAjudarSlide: Dispatch<SetStateAction<SlidesDTO[]>>
  bancoDePerucasMovelSlide: SlidesDTO[]
  setBancoDePerucasMovelSlide: Dispatch<SetStateAction<SlidesDTO[]>>
  depoimentosSlide: DepoimentosDTO[]
  setDepoimentosSlide: Dispatch<SetStateAction<DepoimentosDTO[]>>
}

interface MainProviderProps {
  children: ReactNode
}

export const MainContext = createContext({} as MainContextData)

export const MainProvider = ({ children }: MainProviderProps) => {
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [telefone, setTelefone] = useState('')
  const [phoneMessage, setPhoneMessage] = useState(true)
  const [CEP, setCEP] = useState('')
  const [cepMessage, setCEPMessage] = useState(true)
  const [endereco, setEndereco] = useState('')
  const [enderecoMessage, setEnderecoMessage] = useState(true)
  const [cidade, setCidade] = useState('')
  const [cidadeMessage, setCidadeMessage] = useState(true)
  const [uf, setUF] = useState('')
  const [cidadeField, setCidadeField] = useState('')
  const [success, setSuccess] = useState(false)
  const [progress, setProgress] = useState(15)
  const [protocolo, setProtocolo] = useState('')
  const [data, setData] = useState({} as QueroUmaPerucaDTO)
  const [homeSlide, setHomeSlide] = useState<SlidesDTO[]>([])
  const [queroUmaPerucaSlide, setQueroUmaPerucaSlide] = useState<SlidesDTO[]>(
    [],
  )
  const [comoAjudarSlide, setComoAjudarSlide] = useState<SlidesDTO[]>([])
  const [depoimentosSlide, setDepoimentosSlide] = useState<DepoimentosDTO[]>([])
  const [bancoDePerucasMovelSlide, setBancoDePerucasMovelSlide] = useState<
    SlidesDTO[]
  >([])

  const setFormValues = (
    values:
      | QueroUmaPerucaDTOStep1
      | QueroUmaPerucaDTOStep2
      | QueroUmaPerucaDTOStep3
      | QueroUmaPerucaDTOStep4
      | QueroUmaPerucaDTOStep5,
  ) => {
    setData((prevValues) => ({
      ...prevValues,
      ...values,
    }))
  }

  return (
    <MainContext.Provider
      value={{
        openSubMenu,
        setOpenSubMenu,
        telefone,
        setTelefone,
        phoneMessage,
        setPhoneMessage,
        CEP,
        setCEP,
        cepMessage,
        setCEPMessage,
        endereco,
        setEndereco,
        enderecoMessage,
        setEnderecoMessage,
        cidade,
        setCidade,
        cidadeMessage,
        setCidadeMessage,
        uf,
        setUF,
        cidadeField,
        setCidadeField,
        progress,
        setProgress,
        protocolo,
        setProtocolo,
        success,
        setSuccess,
        data,
        setData,
        setFormValues,
        homeSlide,
        setHomeSlide,
        queroUmaPerucaSlide,
        setQueroUmaPerucaSlide,
        comoAjudarSlide,
        setComoAjudarSlide,
        bancoDePerucasMovelSlide,
        setBancoDePerucasMovelSlide,
        depoimentosSlide,
        setDepoimentosSlide,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export const useMain = () => {
  return useContext(MainContext)
}
